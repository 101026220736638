/* font-family: "Slackside One", cursive; */
/* font-family: "Caveat", cursive; */
/* font-family: "Urbanist", sans-serif; */
/* font-family: "Poppins", sans-serif; */
:root {
  --sketch-image: url("assets/character/sketch.png");
  --primary-color: #136870;
  --primary-color-hover: #136870;
  --secondary-color: #0d0c22;
  --tertiary-color: #f3f3f4;
  --quaternary-color: #9e9ea7;
  --quinary-color: #ffe4f8;
  --primary-text-color: #fff;
  --bg-gradient: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  --bg-image: url("assets/bg-portfolio.webp");
  --card-bg-color: rgba(19, 104, 112, 0.5);
  --menu-bg-color: #000;
}

html {
  background-color: var(--menu-bg-color);
}

body {
  margin: 0;
  font-family: "Urbanist", sans-serif;

  color: var(--primary-text-color);
  background-image: var(--bg-gradient), var(--bg-image);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

/* NAVBAR */

.navbar {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  backdrop-filter: blur(5px);
  z-index: 999;
}

.navbar-item,
.navbar-item > a {
  color: var(--primary-text-color);
  font-size: 22px;
  cursor: pointer;
  text-decoration: none;
}

.navbar-item:hover,
.navbar-item > a:hover {
  transform: scale(1.1);
  transition: 0.2s linear;
  color: var(--primary-text-color);
}

.navbar-item-toggle > a {
  font-size: 36px;
}

/* SECTION */

/* HERO */

.hero-text {
  color: var(--primary-text-color);
}
.char-bg {
  background: var(--sketch-image);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* Cursor */
.txt {
  border-right: 0.2rem solid var(--primary-text-color);
  padding-right: 0.1rem;
  font-family: "Madimi One", sans-serif;
  font-weight: 100;
  animation: blink 0.5s infinite linear;
}
.main-text {
  font-family: "Madimi One", sans-serif;
}
.section-hero {
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 1)
    ),
    url("assets/bg-hero.jpg");
  background-position: center;
  background-size: cover; */
}

@keyframes blink {
  0% {
    border-right: 0.2rem solid var(-hero-text-color);
  }
  50% {
    border-right: 0.2rem solid transparent;
  }
  100% {
    border-right: 0.2rem solid var(-hero-text-color);
  }
}

/* PORTFOLIO */

.section-portfolio {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("assets/bg-portfolio.png");
  background-position: center;
  background-size: cover; */
}

.portfolio-card-top {
  padding: 1.5rem;
  border: 1px solid var(--card-bg-color);
  background: transparent;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-card-bottom {
  padding: 1rem;
  background-color: var(--card-bg-color);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  font-size: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skills */

.section-skills {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
  min-height: 100vh;
}

.skills-card-top {
  min-height: 140px;
  padding: 1.5rem;
  background-color: var(--card-bg-color);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.skills-card-bottom {
  padding: 1rem;
  background: transparent;
  border: 1px solid var(--card-bg-color);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* RESUME BUTTON */

.resume-btn {
  position: fixed;
  bottom: 10px;
  right: 10px !important;
  background-color: var(--primary-color);
  border-radius: 8px;
  width: 120px;
  height: 30px;
  padding: 0rem 1rem;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.resume-btn > a {
  color: var(--primary-text-color);
}

.link-btn {
  background-color: var(--primary-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
}

.open-btn {
  position: relative;
  right: -35px;
  background-color: var(--primary-color);
  border-radius: 12px;
  width: 40px;
  height: 40px;
  padding: 0.21rem 0.21rem;
  cursor: pointer;
}

/* FOOTER & CONTACT */

.last-section {
  min-height: 100vh;
  padding: 80px 0;
  width: 100%;
  /* background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.52) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("assets/bg-contact.jpg");
  background-position: center;
  background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-text {
  color: var(--primary-color);
}

/* FOOTER */

.site-footer {
  padding: 3em 0;
}
.site-footer p:last-child {
  margin-bottom: 0;
}
.site-footer .social-item {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: var(--primary-color);
  position: relative;
  border-radius: 50%;
  font-size: 22px;
  margin: 0 10px;
  cursor: pointer;
}
.site-footer .social-item > span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* CONTACT */

.contact-tag {
  color: var(--primary-text-color);
  text-decoration: none;
}
.contact-tag:hover {
  color: #888;
  transition: 0.2s linear;
}

.site-contact-details,
.site-contact-details li {
  padding: 0;
  margin: 0;
}
/* MENU ITEM */
.menu-container {
  width: 100%;
  height: 100%;
  background-color: var(--menu-bg-color);
  position: fixed;
  top: 0px;
  right: 0px;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 998;
}
.menu {
  z-index: 10;
  width: min-content;
}
.menu .menu-item {
  text-align: left;
  cursor: pointer;
  display: block;
  font-size: 100px;
  text-transform: uppercase;
  color: var(--quinary-color);
  padding: 0.5rem 0;
  opacity: 0.25;
  transition: scale 550ms linear, opacity 250ms linear;
}
.menu .menu-item {
  display: block;
  font-size: 100px;
  text-transform: uppercase;
  color: var(--quinary-color);
  padding: 0.5rem 0;
  opacity: 0.15;
}
.menu:hover .menu-item:not(:hover) {
  transform: scale(1);
  opacity: 0.25;
  transition: scale 550ms linear, opacity 250ms linear, filter 250ms linear;
  filter: blur(4px);
}

.menu .menu-item:hover {
  transform: scale(1);
  opacity: 1;
  transition: scale 550ms linear, opacity 250ms linear;
}

/* MODAL */
.content-bg {
  background-color: rgba(19, 104, 112, 0.5);
  backdrop-filter: blur(10px);
}

.arrow {
  pointer-events: none;
}

.arrow__path {
  stroke: var(--primary-text-color);
  fill: transparent;
  stroke-dasharray: 10 5;
  stroke-width: 1px;
}

.arrow__head line {
  stroke: var(--primary-text-color);
  stroke-width: 1px;
  transform: rotate(-90deg);
}

.input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 0.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
  transition: 0.3s ease;
}

.text-area {
  width: 100%;
  height: 100px;
  line-height: 28px;
  padding: 0 0.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
  transition: 0.3s ease;
}

.input::placeholder .text-area::placeholder {
  color: var(--quaternary-color);
}

.input:focus,
input:hover,
.text-area:focus,
.text-area:hover {
  outline: none;
  border-color: rgba(234, 76, 137, 0.4);
  background-color: var(--primary-text-color);
  box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
}

.send-btn {
  background-color: var(--primary-color);
  color: var(--primary-text-color);
  border-radius: 12px;
  width: 130px;
  border: none;
  height: 50px;
  padding: 0.21rem 0.21rem;
  cursor: pointer;
  font-weight: 600;
}

.send-btn:hover {
  background-color: var(--primary-color-hover);
}

#toggle {
  opacity: 0;
  width: 0;
  height: 0;
}
